<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class=" elevation-1">
            Change Password
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-form ref="changepassword" v-model="valid" lazy-validation>
              <v-container>
                <v-row class="justify-center">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="password"
                      :rules="[rules.required, rules.min]"
                      hint="At least 8 characters"
                      label="Old Password"
                      name="input-10-1"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="newpassword"
                      name="input-10-1"
                      :rules="[rules.required, rules.min]"
                      hint="At least 8 characters"
                      label="New Password"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="confirmPassword"
                          
                          :rules="[rules.required, passwordMatch]"
                          
                          name="input-10-1"
                          label="Confirm Password"
                          @click:append="show1 = !show1"
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                <v-row class="justify-center">
                  <v-col cols="12" sm="6" md="4">
                    <v-btn
                    block
                       outlined color="black"
                      :disabled="!valid"
                     
                      @click="validate()"
                      >Save</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      showsnackbar: false,
      msg: null,
      valid: true,
      password: "",
      newpassword: "",
      confirmPassword: "",
      rules: {
        required: (value) => !!value || "Password is Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
  },
  methods: {
    validate() {
      if (this.$refs.changepassword.validate()) {
        this.update();
      }
    },
    update() {
      var data = {};
      data["password"] = this.password;
      data["newpassword"] = this.newpassword;
      //   data["confirmPassword"] = this.confirmPassword;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/change/password",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>